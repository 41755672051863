<template>
  <div id="about-us">
    <BaseTitle pageTitle="About Us" :pageSubtitle="$t('aboutPageSubtitle')"></BaseTitle>
    <div class="about-us-wrapper">
      <div class="container-left">
        <div class="blue-deco"></div>
        <div class="award-img"></div>
      </div>
      <div class="container-right">
      <div class="about-container">
        <div class="title">About Company</div>
        <div class="sub-title">
          {{$t('aboutCompanySubtitle')}}</div>
        <div class="content">
          {{$t('aboutCompanyContent')}}
        </div>
      </div>
      <div class="award-container">
        <div class="award-container-right">
         <div class="title">Awards</div>
        <div class="sub-title">
          {{$t('aboutAwardsSubtitle')}}</div>
        <div class="award-list">
          <div class="award-item">
            <div class="award-year">2016</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2016_01')}}</div>
          </div>
           <div class="award-item">
            <div class="award-year">2016</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2016_02')}}</div>
          </div>
           <div class="award-item">
            <div class="award-year">2016</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2016_03')}}</div>
          </div>
          <div class="award-item">
            <div class="award-year">2016</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2016_04')}}</div>
          </div>
          <div class="award-item">
            <div class="award-year">2016</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2016_05')}}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2017</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2017_01')}}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2019</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2019_01')}}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2019</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2019_02')}}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2019</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2019_03')}}
            </div>
          </div>
           <div class="award-item">
            <div class="award-year">2020</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2020_01')}}
            </div>
          </div>
           <div class="award-item">
            <div class="award-year">2020</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2020_02')}}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2021</div>
            <div class="award-content">
              {{$t('aboutAwardsContent2021_01')}}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2022</div>
            <div class="award-content">
              {{ $t('aboutAwardsContent2022_01') }}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2022</div>
            <div class="award-content">
              {{ $t('aboutAwardsContent2022_02') }}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2023</div>
            <div class="award-content">
              {{ $t('aboutAwardsContent2023_01') }}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2024</div>
            <div class="award-content">
              {{ $t('aboutAwardsContent2024_01') }}
            </div>
          </div>
          <div class="award-item">
            <div class="award-year">2025</div>
            <div class="award-content">
              {{ $t('aboutAwardsContent2025_01') }}
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";

export default {
    metaInfo: {
      title: 'Home',
    },
  components: {
    BaseTitle
  }
  }
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

.about-us-wrapper {
  padding: 50px 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    padding: 50px;
  }
  @media screen and (max-width: 720px) {
    padding: 50px 30px;
    flex-direction: column-reverse;
  }
}

.about-container, .award-container {
  width: 500px;
  margin-bottom: 80px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    margin-bottom: 50px;
  }
}

.title {
  font-size: 24px;
  color: $mainBlue;
  letter-spacing: 1.6px;
}

.sub-title {
  margin-top:10px;
  font-size: 14px;
  color: #B5B5B5;
}

.content {
  font-size: 14px;
  margin-top: 30px;
  line-height: 26px;
  white-space: pre-wrap;
}

.container-left {
  position: relative;
  margin-right: 60px;
  margin-top: 450px;
  @media screen and (max-width: 720px) {
    margin-top: 0px;
  }
  .blue-deco {
    position: absolute;
    top: 30px;
    z-index: -1;
    width: 264px;
    height: 247px;
    background: $lightBlue;
  }
  .award-img {
    width: 245px;
    height: 327px;
    background-image: url('../assets/imgs/page-banner/about-award.jpg');
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 720px) {
      width: 240px;
      height: 300px;
    }
  }
}

.award-list {
  margin-top: 30px;
  .award-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  line-height: 24px;
  .award-year {
    margin-right: 22px;
   }
  } 
}

/deep/ {
  #base-title {
    .top-banner {
    .banner-photo {
      background-image: url('../assets/imgs/page-banner/about.jpg');
      }
    }
  }
}
</style>
