module.exports = {
  //cookiealert//
  cookieAlert:'為提供您最佳個人化且即時的服務，本網站透過使用 Cookies 記錄與存取您的瀏覽使用訊息。當您使用本網站，即表示您同意 Cookies 技術支援。更多資訊請參閱',
  cookieAlertPrivacy:'隱私權政策。',
  //header//
  zh: '中文',
  en: '英文',
  es: '西班牙文',
  headerAbout:'關於我們',
  headerContact:'聯絡我們',
  //basetitle//
  titleAbout:'關於我們',
  titleHistory:'公司沿革',
  titlePress:'媒體報導',
  //home//
  homeSlogan:'Credit Card Payments for Everyone.',
  homeDescription:'輕碰卡片、輕鬆收款、你的行動收款 App',
  homeServiceTagTap:'信用卡收款的最佳幫手',
  homeServiceTextTap:'你的行動刷卡 App，怎麼收都行！不論企業或個人，均可申請。多種收款方式，適用各種情境。',
  homeServiceTagTapUs:'Credit Card Payments for Everyone.',
  homeServiceTextTapUs:'INSTO Tap 正式登陸北美市場，推出美國版 INSTO Tap US。目前支援企業、個人賣家申請。',
  homeLearnMore:'了解更多',
  homeServiceTagApi:'找金流服務，免煩惱',
  homeServiceTextApi:'網頁串金流，就用 INSTO API！串接簡單，收款方便，省時省力好夥伴。目前推出 2.0 版本，收款更整全！',
  homeServiceTagCredit:'零卡在手，消費更上手',
  homeServiceTextCredit:'INSTO Credit 提供新形態無卡分期消費模式，讓商家擁有多元支付選擇，消費者即使沒信用卡也可輕鬆分期購物。',
  homeServiceTagFlexi:'分期付款、自由運用',
  homeServiceTextFlexi:'Flexi 靈活金讓您的買家無須信用卡也能分期付款，透過發送 Flexi 靈活金訂單，買家完成線上申請，最快當日撥款到帳，大幅增加成交機會。',
  //about//
  aboutPageTitle: 'About Us',
  aboutPageSubtitle: '關於我們',
  aboutCompanySubtitle:'關於盈士多',
  aboutCompanyContent:'INSTO（Installments, Inc.）於 2013 成立於美國堪薩斯市，並在台灣設立研發團隊。「台灣盈士多科技股份有限公司」為 Installments Inc. 在台之子公司。\n2017 年在台灣及美國正式發表上線，INSTO 專注於利用創新科技將金流收款及分期收付款等普惠金融服務帶入手機 App 中，引發廣大的迴響。 \n過程中，團隊不斷研發新的可能性，突破舊有金融產業框架。讓原本的收付款不再受限於繁複的申請手續與硬體系統、而能夠透過我們的產品（INSTO Tap）提供線上及線下多元、客製化、安全收付款服務。 \n如今，我們將 installments 分期核心概念，拓展成 INSTO。讓其服務不僅限於分期付款、定期付款。而是透過手機 App，就能實現全方位收款服務。INSTO 將逐步於各國家落地、並提供更多元的收款方式、信用額度、無卡分期支付等功能。讓用戶能享受更便利、更安全的消費數位金融服務。',
  aboutAwardsSubtitle:'得獎',
  aboutAwardsContent2016_01:'入選 Google Startup Grind Global Conference 2016 新創大會',
  aboutAwardsContent2016_02:'入選新加坡政府 Global Startup Exchange Program',
  aboutAwardsContent2016_03:'獲得 APEC 高峰會 SMEA 首獎，代表台灣前往在秘魯首都利馬舉行的 APEC 年會',
  aboutAwardsContent2016_04:'入選歐洲最大電信商者 Orange Telecom 舉辦的 Orange Fab 全球加速器計畫',
  aboutAwardsContent2016_05:'獲得福岡市科技日新創競賽冠軍 FUKUOKA Tech Day Pitch Winner',
  aboutAwardsContent2017_01:'獲得 NTT DATA ZAMMA Award （NTT DATA 社長獎）',
  aboutAwardsContent2019_01:'獲得臺北國際創業週 創業臺北 Demo Day 優秀團隊',
  aboutAwardsContent2019_02:'獲得 TAITRA Startup Bullpen 第 3 名',
  aboutAwardsContent2019_03:'獲得 COMPUTEX d&i Awards 創新設計獎',
  aboutAwardsContent2020_01:'入選 Google Startup Grind 全球新創大會，並獲選 Accelerate 全球成長前 25 強',
  aboutAwardsContent2020_02:'獲得林口新創園國際創業家大賽 Startup Terrace Global Hack 2020 金融科技優選獎',
  aboutAwardsContent2021_01:'入選 FINOPITCH 2021 國際組前 8 強',
  aboutAwardsContent2022_01:'獲選「臺北市智慧零售技術應用方案代表性業者」，於北市花博農民市集落地，並刊登於臺北市政府公開網站', 
  aboutAwardsContent2022_02: '提名「2022 年度智慧臺北創新獎」，參與年度優質試辦專案選拔',
  aboutAwardsContent2023_01:'入選 Google Startup Grind 全球新創大會，並獲選 Accelerate 全球成長前 40 強',
  aboutAwardsContent2024_01:'獲選「2024 台北金融科技獎」國際市場潛力獎優勝',
  aboutAwardsContent2025_01:'獲得 FINOPITCH Mastercard Award',
  //history//
  historyPageSubtitle: '公司沿革',
  historyContent2013_01:'美國堪薩斯市創立公司',
  historyContent2014_01:'自籌資金 30 萬美元',
  historyContent2015_01:'獲得種子輪投資 190 萬美元',
  historyContent2016_01:'獲得由 SYSTEX 精誠資訊領投的 60 萬美元 Pre-A 輪',
  historyContent2016_02:'為打造全球品牌將 Installments (beta) 更名為 INSTO',
  historyContent2017_01:'交易額突破 100 萬美元',
  historyContent2018_01:'獲得交大天使投資俱樂部 80 萬美元投資',
  historyContent2018_02:'獲得經濟部智慧財產局核准台灣專利',
  historyContent2018_03:'年度交易金額突破 350 萬美元',
  historyContent2019_01:'首家獲得國發基金天使投資方案投資 Pre-A 輪',
  historyContent2019_02:'年度交易金額突破 500 萬美元',
  historyContent2020_01:'矽谷 Plug & Play Ventures 加入 Pre-A 輪投資',
  historyContent2020_02:'單月交易額突破 100 萬美元',
  historyContent2021_01:'與台北富邦銀行簽訂合作，產品 INSTO Tap 上線',
  historyContent2022_01:'與美國收單行美國合眾銀行 US Bank 簽約',
  historyContent2024_01:'INSTO Tap 進軍北美市場、於台灣舉辦 Beta Lauch、美國堪薩斯舉辦 Soft Lauch',
  investSeedTitle:'種子投資',
  investSeedContent:'領投者為矽谷橡子園（Acorn Campus）共同創辦人林富元（Bob Lin）與陳五福（Wufu Chen）等天使所組成的多元天使群（Multi-Dimentional Angel Partners）。參與投資人包括矽谷排名第一，曾幫 Apple 與 Google 成立公司的律師事務所 WSGR（Wilson Sonsini Goodrich & Rosati）、阿碼科技創辦人黃耀文（Wayne Huang）、阿里巴巴集團前董事、清大水木基金、以及達盈創投（Darwin Venture）等。',
  investPreATitle:'Pre-A 融資',
  investPreAContent:'2016 年起從 SYSTEX 精誠資訊、交大天使俱樂部、台灣國發基金，以及矽谷 Plug and Play Ventures 等機構獲得共超過 200 萬美元的 Pre-A 輪投資。',
  investATitle:'Series A 融資',
  investAContent:'進行中。',
  //press//
  pressPagesubTitle:'媒體報導',
  //contact//
  contactPageTitle:'Contact Us',
  contactPagesubTitle:'聯絡我們',
  contactDescription:'我們非常重視您的意見，請在此處留下問題或意見回饋，亦可於上班時間來信聯絡客服人員，我們將竭誠為您服務。',
  contactSubdescription: '客服服務時間：\n週一至週五早上 10:00 至 下午6:00 （國定假日、例假日除外）',
  contactFormName: '稱呼',
  contactFormNamePlaceholder: '請輸入稱呼',
  contactFormEmail:'電子信箱',
  contactFormEmailPlaceholder:'請輸入電子信箱',
  contactFormType: '需求類別',
  contactFormType1: '意見反應',
  contactFormType2: '投資合作',
  contactFormType3: '媒體採訪',
  contactFormType4: '其他',
  contactFormTitle: '標題主旨',
  contactFormTitlePlaceholder: '請輸入主旨',
  contactFormText: '內容',
  contactFormTextPlaceholder: '請輸入內容',
  contactFormSubmit: '確定發送',
  contactFormAlert:'謝謝您的留言，我們會盡快回覆您。',
  //api//
  apiTitle:'使用簡單、無縫接軌',
  apiText:'INSTO 收款 API 使用簡單，只要成為 INSTO 賣家（個人、企業）皆可使用，無需額外處理信用卡相關規範，即可在您的網站串接金流服務、簡化買賣支付流程。INSTO API 不論是在 App 內、網站上，都能無縫接軌，無須另外透過額外的中介頁面，讓您的使用者有更好的付款體驗。',
  apiGet:'索取 API 文件',
  apiType:'API 規格編碼為 utf-8',
  apiNotice:'預計 2021 年 7 月開放此功能，敬請期待!',
  apiModalTitle:'索取 API 文件',
  apiModalApplySeller:'尚未成為賣家',
  apiModaltext_apply:'立即下載 INSTO Tap App，並註冊申請成為『企業』或『個人』賣家，方可下載 API 文件。',
  apiModalSeller:'已經是賣家',
  apiModaltext_download_login:'請登入',
  apiModaltext_download_url:'INSTO Tap 賣家後台',
  apiModaltext_download:'『 API 設定』下載 API 手冊，若有任何疑問，歡迎來信 ',
  apiContact:'聯絡客服',
  apiPagesubTitle:'金流串接',
  //credit//
  creditPagesubTitle:'無卡分期',
  creditSellerTitle:'賣家一次收款',
  creditSellerText:'INSTO Credit 無卡分期讓賣家一次收齊款項，買家審核通過一週內即可撥款。賣家可發送無卡分期訂單連結給您的客戶，並自行決定金額、分期數、負擔利率等條件，收款彈性。',
  creditSellerNotice:'很抱歉，目前暫時無法提供無卡分期服務，感謝您的支持！',
  // creditSellerFaq:'常見問題',
  creditBuyerTitle:'買家分期支付',
  creditBuyerText:'買家收到無卡分期訂單，只需線上填寫資料便可送出申請，審核通過後，訂單即刻成立！買家將會收到繳款簡訊、E-mail，包含超商條碼或 ATM 帳號，只需按時到超商 /ATM 繳款即可！',
  creditBuyerReviewNotice1: '審核時間為',
  creditBuyerReviewNotice2: '平日 08:40~21:00/假日 10:00~18:00',
  creditBuyerReviewNotice3: '平日 20:00 前申請案件將於當天完成審核，假日 17:00 前申請案件將於在當天完成審核，如超過則等待隔日審核。',
  creditBuyerPayNotice1:'INSTO 無卡分期提供',
  creditBuyerPayNotice2:'「7-11/全家/萊爾富」超商繳款，ATM 支援台灣地區全銀行帳戶繳款。',

  //footer//
  footerAboutCompany:'關於我們',
  footerAboutHistory:'公司沿革',
  footerAboutPress:'媒體報導',
  footerfollowContact:'聯絡我們',
  footerfollowfaq:'常見問題',
  footerTermsService:'會員條款',
  footerTermsPrivacy:'隱私權政策',
  footerOrginLogin:'舊版網頁登入入口',
  //404//
  pagenotfoundTitle:'糟糕！',
  pagenotfoundContent:'找不到此網頁，請確認您的網址是否正確',
  pagenotfoundAction:'回到首頁',

}
