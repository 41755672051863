module.exports = {
  //cookiealert//
  cookieAlert:'Para proporcionar la mejor experiencia de usuario personalizada, INSTO utiliza cookies para ayudar a operar el sitio web. Al navegar por este sitio, acepta la colocación de cookies opcionales en su dispositivo. Para más información, por favor revise la ',
  cookieAlertPrivacy:'Política de Privacidad.',
  //header//
  zh: 'Chino',
  en: 'Inglés',
  es:'Español',
  headerAbout:'Sobre Nosotros',
  headerContact:'Contáctenos',
  //basetitle//
  titleAbout:'Sobre Nosotros',
  titleHistory:'Nuestra Historia',
  titlePress:'Prensa',
  //home//
  homeSlogan:'Pagos con tarjeta de crédito para todos.',
  homeDescription:'¿Aún utiliza un lector de tarjetas de crédito con cuotas mensuales de alquiler y funcionalidades limitadas? ¡Pruebe INSTO Tap! La mejor aplicación para convertir su teléfono en un infalible lector de tarjetas. Realice sus ventas y proceda con el cobro en cualquier momento y lugar.',
  homeServiceTagTap:'Recibe pagos en cualquier momento y lugar.',
  homeServiceTextTap:'¡Acepte pagos con tarjeta de crédito en cualquier momento y lugar! Nuestra aplicación ofrece múltiples soluciones de pago, incluidas opciones con tarjeta de crédito y pagos a plazos, adaptadas tanto para negocios como para vendedores individuales.',
  homeServiceTagTapUs:'Pagos con tarjeta de crédito para todos.',
  homeServiceTextTapUs:'INSTO Tap se lanza oficialmente en América del Norte con INSTO Tap US, ahora disponible para empresas y vendedores individuales.',
  homeLearnMore:'Saber más',
  homeServiceTagApi:'¿Está buscando una solución de pago? ¡No se preocupe!',
  homeServiceTextApi:'Con la integración fluida de la API de INSTO, aceptar pagos con tarjeta de crédito en su sitio web o aplicación es simple, rápido y conveniente.',
  homeServiceTagCredit:'¿No tiene tarjeta de crédito? ¡Pruebe INSTO Credit!',
  homeServiceTextCredit:'INSTO Credit ofrece un plan de pagos a plazos, permitiendo a los vendedores brindar opciones de financiamiento a sus clientes, incluso si no tienen tarjeta de crédito.',
  homeServiceTagFlexi:'Pago a plazos, uso flexible',
  homeServiceTextFlexi:'Con INSTO Flexi, los vendedores pueden ofrecer a sus clientes un plan de pago a plazos sin tarjeta de crédito. A través de órdenes Flexi, los compradores pueden completar su aplicación en línea, y los fondos se pueden asignar en un día como pronto.',
  //about//
  aboutPageTitle: 'Sobre Nosotros',
  aboutPageSubtitle: '',
  aboutCompanySubtitle:'Sobre INSTO',
  aboutCompanyContent:'INSTO (Installments, Inc.) fue fundada en Kansas City, EE.UU., con un equipo de desarrollo en Taiwán, y se lanzó oficialmente en 2017 tanto en Taiwán como en EE.UU.\n Comenzamos diseñando una aplicación para llevar el concepto de servicios de recolección y pago de cuotas a través de la web móvil, lo que generó un gran interés. Desde entonces, hemos estado incorporando nuevas características según la respuesta del mercado, reformando eficazmente el antiguo marco de los servicios financieros. Los pagos ya no están limitados por complicados procedimientos de solicitud ni sistemas de hardware; ahora se pueden realizar a través de INSTO Tap y diversas API, lo que permite ofrecer servicios de recolección de pagos diversificados, personalizados y seguros, tanto en línea como fuera de línea.\nHoy en día, INSTO no solo ofrece servicios de recolección de pagos con tarjeta de crédito y pagos a plazos, sino también una gama completa de servicios financieros para individuos y empresas a través de aplicaciones móviles y API web\nINSTO está expandiendo gradualmente sus servicios en varios países, ofreciendo más formas de recolectar pagos. Además de la recolección con tarjeta de crédito, INSTO proporcionará nuevas funciones como líneas de crédito rápidas, pagos a plazos sin tarjeta, recolección de cuentas eACH y financiamiento de la cadena de suministro, con el fin de ayudar a los usuarios de todos los sectores a disfrutar de servicios financieros digitales más convenientes y seguros.',
  aboutAwardsSubtitle:'INSTO lleva el fintech de Taiwán al mundo',
  aboutAwardsContent2016_01:'Seleccionado para la Conferencia Global de Google Startup Grind 2016',
  aboutAwardsContent2016_02:'Seleccionado por el programa Global Startup Exchange del Gobierno de Singapur',
  aboutAwardsContent2016_03:'Premio en primer lugar en la Cumbre APEC SMEA, representando a Taiwán en la Reunión Anual de APEC en Lima, Perú',
  aboutAwardsContent2016_04:'Seleccionado para el programa Global Accelerator de Orange Fab por Orange Telecom, el mayor proveedor de telecomunicaciones de Europa',
  aboutAwardsContent2016_05:'Ganador del concurso de innovación de la Ciudad de Ciencia y Tecnología de Fukuoka',
  aboutAwardsContent2017_01:'Ganador del Premio del Presidente de NTT DATA',
  aboutAwardsContent2019_01:'Ganador de la Semana Internacional del Emprendimiento de Taipei, Premio a los Equipos Sobresalientes del Taipei Demo Day',
  aboutAwardsContent2019_02:'Premiado en tercer lugar en el TAITRA Startup Bullpen',
  aboutAwardsContent2019_03:'Ganador de los premios COMPUTEX d&i, Premio al Diseño Innovador',
  aboutAwardsContent2020_01:'Seleccionado por Google Startup Grind como una de las 25 principales empresas globales en crecimiento por Accelerate',
  aboutAwardsContent2020_02:'Ganador del Premio Fintech Startup Terrace Global Hack 2020 en la Competencia Internacional de Emprendimiento en el Parque de Nuevos Emprendedores de Linkou',
  aboutAwardsContent2021_01:'Seleccionado entre los 8 finalistas en el FINOPITCH 2021 International Group',
  aboutAwardsContent2022_01:'Seleccionado como el "Proveedor Representante de Soluciones de Tecnología de Venta Inteligente en la Ciudad de Taipei", presentado en el Mercado de Agricultores del Taipei Flower Expo, y destacado en el sitio web oficial del Gobierno de la Ciudad de Taipei.',
  aboutAwardsContent2022_02:'Nominado para el "Premio a la Innovación de Taipei 2022", participando en la selección de los proyectos piloto de alta calidad del año.',
  aboutAwardsContent2023_01:'Seleccionado por Google Startup Grind como una de las 40 principales empresas globales en crecimiento por Accelerate.',
  aboutAwardsContent2024_01:'Seleccionado como ganador del "Premio al Potencial de Mercado Internacional" en los "Premios Fintech Taipei 2024.',
  aboutAwardsContent2025_01:'Ganador del Premio FINOPITCH Mastercard',
  //history//
  historyPageSubtitle: 'Nuestra Historia',
  historyContent2013_01:'La empresa fue fundada en Kansas City, EE.UU.',
  historyContent2014_01:'Recauda fondos por US$300,000',
  historyContent2015_01:'Obtiene una ronda de financiación inicial de US$1.9 millones',
  historyContent2016_01:'Asegura una ronda pre-A de US$600,000 liderada por SYSTEX Jingcheng Information',
  historyContent2016_02:'Renombra Installments (beta) a INSTO para construir una marca global',
  historyContent2017_01:'El volumen de transacciones supera US$1 millón',
  historyContent2018_01:'Recibe una inversión de US$800,000 del Club de Inversión Angel NCTU',
  historyContent2018_02:'Obtiene la aprobación de la patente de Taiwán de la Oficina de Propiedad Intelectual del Ministerio de Asuntos Económicos',
  historyContent2018_03:'El monto anual de transacciones supera US$3.5 millones',
  historyContent2019_01:'Primera empresa en recibir una ronda de financiación pre-A del Programa de Inversión Angel del Fondo Nacional de Desarrollo',
  historyContent2019_02:'El monto anual de transacciones supera US$5 millones',
  historyContent2020_01:'Silicon Valley Plug & Play Ventures se une a la ronda de inversión pre-A',
  historyContent2020_02:'El volumen de transacciones mensual supera US$1 millón',
  historyContent2021_01:'El producto INSTO Tap se lanzó en asociación con Taipei Fubon Bank.',
  historyContent2022_01:'INSTO ha firmado un contrato con US Bank, un importante adquirente en los Estados Unidos.',
  historyContent2024_01:'INSTO Tap ingresa al mercado de América del Norte, con un lanzamiento Beta en Taiwán y un lanzamiento Soft en Kansas, EE. UU.',
  investSeedTitle:'Inversores Semilla',
  investSeedContent:'El principal inversor es Multi-Dimensional Angel Partners, un grupo formado por Bob Lin y Wufu Chen, cofundadores de Acorn Campus en Silicon Valley. Entre los inversores participantes se incluyen WSGR (Wilson Sonsini Goodrich & Rosati), una firma de abogados clasificada como la número 1 en Silicon Valley, que ayudó a empresas como Apple y Google a establecerse; Wayne Huang, fundador de Armorize Technology; un exdirector de Alibaba Group; el Fondo Shuimu de la Universidad de Tsinghua; Darwin Venture, entre otros.',
  investPreAContent:'Financiación pre-A obtenida de SYSTEX, el Club de Inversión Angel NCTU, el Fondo Nacional de Desarrollo de Taiwán, y Silicon Valley Plug and Play Ventures desde 2016, con un total de superior a US$2 millones.',
  investATitle:'Serie A',
  investAContent:'En progreso',
  //press//
  pressPagesubTitle:'',
  //contact//
  contactPageTitle: 'Contáctenos',
  contactPagesubTitle: '',
  contactDescription: 'Valoramos mucho su opinión, por favor deje sus comentarios aquí, o contáctenos por correo electrónico (service.us@ins.to). ¡Le responderemos lo antes posible!',
  contactSubdescription: 'Horario de atención al cliente: \n Lunes a Viernes de 10 AM a 6 PM (GMT+8, excepto fines de semana y días festivos)',
  contactFormName: 'Nombre',
  contactFormNamePlaceholder: 'Por favor ingrese su nombre',
  contactFormEmail: 'Dirección de correo electrónico',
  contactFormEmailPlaceholder: 'Por favor ingrese su dirección de correo electrónico',
  contactFormType: 'Categoría',
  contactFormType1: 'Sugerencia',
  contactFormType2: 'Inversión y Cooperación',
  contactFormType3: 'Entrevista de Prensa',
  contactFormType4: 'Otros',
  contactFormTitle: 'Título',
  contactFormTitlePlaceholder: 'Por favor ingrese el título',
  contactFormText: 'Contenido',
  contactFormTextPlaceholder: 'Por favor ingrese el contenido',
  contactFormSubmit: 'Enviar',
  contactFormAlert: '¡Gracias por su mensaje, le responderemos lo antes posible!',

  //api//
  apiTitle: 'Simple y sin interrupciones',
  apiText: 'La API de INSTO es intuitiva y fácil de usar. Una vez que te conviertes en vendedor de INSTO, ya sea como empresa o particular, puedes integrar fácilmente los pagos en tu sitio web para aceptar transacciones en línea o en persona. Sin preocuparte por la complejidad de las regulaciones de tarjetas de crédito, INSTO te ofrece una API de pago segura y optimizada. Además, con nuestra integración fluida, garantizamos la mejor experiencia de pago para tus clientes.',
  apiGet: 'Obtener documento de API',
  apiType: 'Tipo de API: utf-8',
  apiNotice: 'La API estará disponible en julio de 2021, ¡estén atentos!',
  apiModalTitle: 'Obtener documento de API',
  apiModalApplySeller: 'Aún no soy vendedor.',
  apiModaltext_apply: 'Descarga la aplicación INSTO Tap, regístrate y solicita ser un vendedor empresarial/personal, luego podrás obtener el documento de la API.',
  apiModalSeller: 'Soy un vendedor de INSTO.',
  apiModaltext_download_login: 'Por favor inicie sesión',
  apiModaltext_download_url: 'Página del portal de INSTO Tap',
  apiModaltext_download: ', y ve a "Configuración de API" para descargar el documento. Si tienes preguntas, por favor contacta a ',
  apiContact: 'el equipo de atención al cliente.',
  apiPagesubTitle: 'Crea tu propia solución de pago integrada',

  //credit//
  creditPagesubTitle: '¡Realiza pagos a plazos sin necesidad de tarjeta de crédito!',
  creditSellerTitle: 'El vendedor recibe el pago completo por adelantado',
  creditSellerText: 'Con INSTO Credit, una vez aprobada la solicitud de pago a plazos, el vendedor recibe el importe total por adelantado. Además, puede personalizar las condiciones de pago, incluyendo el monto de cada plazo, el período y la tasa de interés.',
  creditSellerNotice: 'Lo sentimos, pero actualmente no podemos ofrecer el servicio de INSTO Credit.',
  creditBuyerTitle: 'El comprador realiza pagos a plazos.',
  creditBuyerText: 'Al recibir una orden de INSTO Credit, el comprador puede completar los formularios de revisión en línea. Una vez aprobada la orden, el comprador recibirá un "aviso de pago" por correo electrónico y mensaje de texto. Con el código de barras en el aviso de pago, la orden podrá ser pagada fácilmente en una tienda de conveniencia o en el cajero automático.',
  creditBuyerReviewNotice1: 'Horas de revisión',
  creditBuyerReviewNotice2: 'Días laborables 8:40~21:00 / Fines de semana 10:00~18:00',
  creditBuyerReviewNotice3: 'Las órdenes antes de las 20:00 en días laborables serán revisadas el mismo día, las órdenes antes de las 17:00 en fines de semana serán revisadas el mismo día, otras órdenes serán revisadas al día siguiente.',
  creditBuyerPayNotice1: 'INSTO Credit puede ser pagado en',
  creditBuyerPayNotice2: 'tiendas de conveniencia "7-11/ FamilyMart / Hi-Life" y cajeros automáticos.',

  //footer//
  footerAboutCompany: 'Compañía',
  footerAboutHistory: 'Historia',
  footerAboutPress: 'Prensa',
  footerfollowContact: 'Contáctanos',
  footerfollowfaq: 'Preguntas Frecuentes',
  footerTermsService: 'Términos del Servicio',
  footerTermsPrivacy: 'Política de Privacidad',
  footerOrginLogin: 'Iniciar sesión en la versión anterior del sitio web',

  //404//
  pagenotfoundTitle: '¡Ooops!',
  pagenotfoundContent: 'Página no encontrada, por favor confirme la dirección de su sitio web.',
  pagenotfoundAction: 'Volver a la página principal',
};
