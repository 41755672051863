module.exports = {
  //cookiealert//
  cookieAlert:'In order to provide the best personalized user experience, INSTO use cookies to help operate website. When browsing this website, you are agreed to the placement of optional cookies on your device. For more information, please review ',
  cookieAlertPrivacy:'Privacy Policy.',
  //header//
  zh: 'Chinese',
  en: 'English',
  es:'Spanish',
  headerAbout:'About Us',
  headerContact:'Contact Us',
  //basetitle//
  titleAbout:'About Us',
  titleHistory:'Our History',
  titlePress:'Press',
  //home//
  homeSlogan:'Credit Card Payments for Everyone.',
  homeDescription:'Still using a credit card reader with monthly rental fees and limited functionalities? Try INSTO Tap! One app that turns your phone into a credit card machine.',
  homeServiceTagTap:'Collect payments anytime, anywhere.',
  homeServiceTextTap:'Your mobile payment app—accepts payments anytime, anywhere! With credit card payments and installment options, INSTO Tap TW provides flexible payment methods for both business and personal sellers.',
  homeServiceTagTapUs:'Credit Card Payments for Everyone.',
  homeServiceTextTapUs:'INSTO Tap officially launches in the North American market with the introduction of INSTO Tap US. Now available for businesses and individual sellers to apply.',
  homeLearnMore:'Learn More',
  homeServiceTagApi:'Looking for a payment solution? No worries!',
  homeServiceTextApi:'With seamless INSTO API integration, accepting credit card payments on your website or App is simple, easy, and convenient!',
  homeServiceTagCredit:'No credit cards? Try INSTO Credit!',
  homeServiceTextCredit:'INSTO Credit offers an installment plan, enabling sellers to provide customers with installment payment options, even without a credit card.',
  homeServiceTagFlexi:' Installment payment、Flexible use',
  homeServiceTextFlexi:'With INSTO Flexi, sellers can offer their customer installment payment plan without credit card. Through Flexi orders, buyers can complete their application online, and the funds can be allocated in a day at the soonest.',
  //about//
  aboutPageTitle: 'About Us',
  aboutPageSubtitle: '',
  aboutCompanySubtitle:'About INSTO',
  aboutCompanyContent:'INSTO (Installments, Inc.) was founded in Kansas City, USA, with a development team in Taiwan, and was officially launched in Taiwan and the U.S. in 2017.\nWe started by designing an app to bring the concept of installment collection and payment services via the mobile Web, generating a lot of interest. Since then, we have been adding new features based on the market response, effectively reshaping the old financial service framework. Payments are no longer restricted by complicated application procedures and hardware systems, but can be provided through INSTO Tap and various APIs to provide diversified, customized and secure payment collection services online and offline.\nToday, INSTO provides not only credit card collection and installment payments, but also a comprehensive range of financial services to individuals and businesses via mobile apps and Web APIs.\nINSTO is now gradually expanding its services in various countries, offering more ways to collect payments. In addition to credit card collection, INSTO will also provide new features such as fast credit lines, cardless installments, eACH accounts collection, and supply chain financing, to help users in all sectors enjoy more convenient and secure digital financial services.',
  aboutAwardsSubtitle:'INSTO brings Taiwan\'s Fintech to the world',
  aboutAwardsContent2016_01:'Selected for the Google Startup Grind Global Conference 2016',
  aboutAwardsContent2016_02:'Selected by the Singapore Government\'s Global Startup Exchange Program',
  aboutAwardsContent2016_03:'Awarded the first prize of APEC Summit SMEA, representing Taiwan to the APEC Annual Meeting held in Lima, Peru',
  aboutAwardsContent2016_04:'Selected for the Orange Fab Global Accelerator Program by Orange Telecom, Europe\'s largest telecommunications provider',
  aboutAwardsContent2016_05:'Winner of the Fukuoka City Science and Technology Day Innovation Competition',
  aboutAwardsContent2017_01:'Winner of the NTT DATA President Award',
  aboutAwardsContent2019_01:'Winner of Taipei International Entrepreneurship Week, Taipei Demo Day Outstanding Team',
  aboutAwardsContent2019_02:'Awarded 3rd place at the TAITRA Startup Bullpen',
  aboutAwardsContent2019_03:'Winner of the COMPUTEX d&i Awards, Innovation Design Award',
  aboutAwardsContent2020_01:'Selected by Google Startup Grind as one of the top 25 global growth companies by Accelerate',
  aboutAwardsContent2020_02:'Winner of the Fintech Startup Terrace Global Hack 2020 Award at the International Entrepreneur Competition in Linkou New Venture Park',
  aboutAwardsContent2021_01:'Selected in the top 8 finalists by the FINOPITCH 2021 International Group',
  aboutAwardsContent2022_01:'Selected as the "Representative Provider of Smart Retail Technology Solutions in Taipei City," showcased at the Taipei Flower Expo Farmer\'s Market, and featured on the Taipei City Government\'s official website.',
  aboutAwardsContent2022_02:'Nominated for the "2022 Taipei Innovation Award," participating in the selection of the annual high-quality trial projects.',
  aboutAwardsContent2023_01:'Selected by Google Startup Grind as one of the top 40 global growth companies by Accelerate.',
  aboutAwardsContent2024_01:'Selected as a winner of the "2024 Fintech Taipei Awards" for the International Market Potential Award.',
  aboutAwardsContent2025_01:'Winner of the FINOPITCH Mastercard Award',
  //history//
  historyPageSubtitle: '',
  historyContent2013_01:'Company is founded in Kansas City, USA',
  historyContent2014_01:'Raises funds of US$300,000',
  historyContent2015_01:'Obtains a seed round funding of US$1.9 million',
  historyContent2016_01:'Secures a US$600,000 pre-A round led by SYSTEX Jingcheng Information',
  historyContent2016_02:'Renames Installments (beta) to INSTO to build a global brand',
  historyContent2017_01:'Transaction volume exceeds US$1 million',
  historyContent2018_01:'Receives an investment of US$800,000 from the NCTU Angel Investment Club',
  historyContent2018_02:'Obtains Taiwan’s patent approval from the Intellectual Property Office, Ministry of Economic Affairs',
  historyContent2018_03:'Annual transaction amount exceeds US$3.5 million',
  historyContent2019_01:'First company to receive a pre-A round funding from the National Development Fund\'s Angel Investment Program',
  historyContent2019_02:'Annual transaction amount exceeds US$5 million',
  historyContent2020_01:'Silicon Valley Plug & Play Ventures joins the pre-A round of investment',
  historyContent2020_02:'Monthly deal volume exceeds US$1 million',
  historyContent2021_01:'INSTO Tap product launched in partnership with Taipei Fubon Bank.',
  historyContent2022_01:'INSTO has partnered with US Bank, a major acquirer in the United States.',
  historyContent2024_01:'INSTO Tap enters the North American market, hosting a Beta Launch in Taiwan and a Soft Launch in Kansas, USA.',
  investSeedTitle:'Seed Investors',
  investSeedContent:'The lead investor is Multi-Dimensional Angel Partners, a group formed by Bob Lin and Wufu Chen, co-founders of Acorn Campus in Silicon Valley.\nParticipating investors include WSGR (Wilson Sonsini Goodrich & Rosati), a law firm ranked No. 1 in Silicon Valley that helped Apple and Google set up companies; Wayne Huang, founder of Armorize Technology; a former director of Alibaba Group; Tsinghua University Shuimu Fund;  Darwin Venture, etc.',
  investPreATitle:'Pre-A Financing',
  investPreAContent:'Pre-A funding over US$2 million from SYSTEX, NCTU Angel Investment Club, Taiwan National Development Fund, and Plug and Play Ventures in Silicon Valley since 2016.',
  investATitle:'Series A',
  investAContent:'In progress',
  //press//
  pressPagesubTitle:'',
  //contact//
  contactPageTitle:'Contact Us',
  contactPagesubTitle:'',
  contactDescription:'Your opinion is highly valued, please leave your comments here, or contact us via Email(service.us@ins.to). We will get back to you ASAP.',
  contactSubdescription: 'Customer service hours: \n Monday to Friday 10 AM to 6 PM (GMT+8, excluding weekends and holidays)',
  contactFormName: 'Name',
  contactFormNamePlaceholder: 'Please enter your name',
  contactFormEmail:'E-mail Address',
  contactFormEmailPlaceholder:'Please enter your e-mail address',
  contactFormType: 'Category',
  contactFormType1: 'Suggestion',
  contactFormType2: 'Investment & Cooperation',
  contactFormType3: 'Press Interview',
  contactFormType4: 'Others',
  contactFormTitle: 'Title',
  contactFormTitlePlaceholder: 'Please enter title',
  contactFormText: 'Content',
  contactFormTextPlaceholder: 'Please enter content',
  contactFormSubmit: 'Send',
  contactFormAlert:'Thank you for your message, we will reply you as soon as possible!',
  //api//
  apiTitle:'Simple and Seamless',
  apiText:'INSTO\'s payment API is easy to use. Once you become an INSTO seller (individual or business), you can integrate payment services and simplify the purchasing process on your website without dealing with credit card regulations. The INSTO API seamlessly integrates into both your app and website, eliminating the need for intermediary pages and providing users with a smoother payment experience.',
  apiGet:'Get API document',
  apiType:'API Type: utf-8',
  apiNotice:'API will be available on July,2021, stay tuned!',
  apiModalTitle:'Get API document',
  apiModalApplySeller:'I’m not a seller yet.',
  apiModaltext_apply:'Download INSTO Tap App, sign up and apply for business/personal seller, then you may get the API document.',
  apiModalSeller:'I’m a INSTO seller.',
  apiModaltext_download_login:'Please login',
  apiModaltext_download_url:'INSTO Tap Portal Page',
  apiModaltext_download:', and go to “API Settings” to download document. If you have questions, please contact ',
  apiContact:'Contact CS team.',
  apiPagesubTitle:'Create your own built-in payment solution',

  //credit
  creditPagesubTitle:'Pay in installments, no credit card required!',
  creditSellerTitle:'Full payment received by the seller in advance',
  creditSellerText:'INSTO Credit enables sellers to receive full payment upfront, with funds disbursed to buyers within a week of approval. Sellers can send cardless installment links to customers, setting their own terms for amounts, installments, and interest rates for flexible payment options.',
  creditSellerNotice:"Sorry, we currently can't offer INSTO Credit service. Thank you for your support!",
  // creditSellerFaq:'INSTO Credit FAQ',
  creditBuyerTitle:'Installment payment for buyers',
  creditBuyerText:'Once buyers receive the cardless installment order, they simply need to fill in their information online to submit the application. After approval, the order is instantly confirmed! Buyers will receive payment notifications via SMS and email, including a convenience store barcode or ATM account number. They just need to make the payment at a convenience store or ATM on time!',
  creditBuyerReviewNotice1: 'Review hours',
  creditBuyerReviewNotice2: 'Weekdays 8:40~21:00 / Weekend 10:00~18:00',
  creditBuyerReviewNotice3: 'Cases before 20:00 on weekdays will be reviewed on the same day, case before 17:00 on weekends will be reviewed on the same day, other cases will be reviewed on the day after. ',
  creditBuyerPayNotice1:'INSTO Credit can be paid in',
  creditBuyerPayNotice2:'convenience store “7-11/ FamilyMart / Hi-Life” and ATM.',

  //footer//
  footerAboutCompany:'Company',
  footerAboutHistory:'History',
  footerAboutPress:'Press',
  footerfollowContact:'Contact us',
  footerfollowfaq:'FAQ',
  footerTermsService:'Terms of Service',
  footerTermsPrivacy:'Privacy Policy',
  footerOrginLogin:'Login to old version website',
  //404//
  pagenotfoundTitle:'Ooooops!',
  pagenotfoundContent:'Page Not Found, please confirm your website address!',
  pagenotfoundAction:'Back to Homepage',
}
