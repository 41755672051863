<template>
  <div id="press">
    <BaseTitle pageTitle="Press" :pageSubtitle="$t('pressPagesubTitle')"></BaseTitle>
    <div class="press-wrapper">
      <PressList :list="list" v-if="$i18n.locale === 'zh'"></PressList>
      <PressList :list="listUSA" v-if="$i18n.locale === 'en' || $i18n.locale === 'es'"></PressList>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";
import PressList from "@/components/PressList";

export default {
    metaInfo: {
      title: 'Home',
    },
  components: {
    BaseTitle,
    PressList
  },
  data() {
    return {
      list: [
      {
          name: 'FinTechSpace 金融科技創新園區',
          url: 'https://www.fintechspace.com.tw/zh-hant/fintech_news/%E5%8F%B0%E7%81%A3%E9%87%91%E8%9E%8D%E7%A7%91%E6%8A%80%E9%96%83%E8%80%80%E5%9C%8B%E9%9A%9B%EF%BC%81-fintechspace%E8%BC%94%E5%B0%8E%E4%B8%89%E6%96%B0%E5%89%B5%E5%8B%87%E9%97%96%E6%97%A5%E6%9C%ACfinopit/',
          title: '台灣金融科技閃耀國際！ FinTechSpace輔導三新創勇闖日本FINOPITCH 2025',
          date: '2025/03/07'
        },
      {
          name: '聯合新聞網',
          url: 'https://udn.com/news/story/7239/8331772',
          title: '第二屆2024台北金融科技獎優勝團隊揭曉 四大獎項引領金融未來',
          date: '2024/11/01'
        },
        {
          name: '創業小聚',
          url: 'https://meet.bnext.com.tw/articles/view/51437?fbclid=IwZXh0bgNhZW0CMTAAAR3913LmivJCwW3VkCV4kDKGVwTxJhtxFAuEexYVFCKW8OWh9sVUT0rO0ws_aem_IZiTQXEyIFt-lw--eOnHTQ',
          title: '2次轉型、1場疫情與10年深耕：金融科技公司INSTO進軍美國的3大關鍵',
          date: '2024/06/24'
        },
        {
          name: '聯合新聞網',
          url: 'https://udn.com/news/story/7239/8001646',
          title: '金融科技公司 INSTO 與美國合眾銀行合作 進軍北美市場',
          date: '2024/05/31'
        },
        {
          name: '經濟日報',
          url: 'https://money.udn.com/money/story/5613/8001646',
          title: '金融科技公司 INSTO 與美國合眾銀行合作 進軍北美市場',
          date: '2024/05/31'
        },
         {
          name: '創業小聚 x 亞洲手創展',
          url: 'https://meet.bnext.com.tw/articles/view/50692?',
          title: '小工作室必收藏！推薦6款「一人公司」必備工具，讓營運一路順遂',
          date: '2023/09/09'
        },
        {
          name: '電腦王阿達',
          url: 'https://tw.news.yahoo.com/%E4%BF%A1%E7%94%A8%E5%8D%A1%E6%94%B6%E6%AC%BE%E4%B8%8D%E9%A0%88%E5%88%B7%E5%8D%A1%E6%A9%9F%EF%BC%81insto-tap-%E4%BD%A0%E7%9A%84%E8%A1%8C%E5%8B%95%E5%88%B7%E5%8D%A1-app-%E4%BD%BF%E7%94%A8%E9%AB%94%E9%A9%97%EF%BC%8C%E6%84%9F%E6%87%89%E6%94%B6%E6%AC%BE%E8%B6%85%E6%96%B9%E4%BE%BF%EF%BC%81%E5%85%8D%E7%A7%9F%E9%87%91%E3%80%81%E5%85%8D%E6%9C%88%E8%B2%BB%E3%80%81%E4%B8%8B%E8%BC%89%E5%85%8D%E8%B2%BB-092645990.html',
          title: '信用卡收款不須刷卡機！INSTO Tap 你的行動刷卡 App 使用體驗，感應收款超方便！免租金、免月費、下載免費',
          date: '2021/09/10'
        },
         {
          name: '電獺少女',
          url: 'https://agirls.aotter.net/post/59442?fbclid=IwAR3Eo6ww9SfWXY0lPOvpz08JuJ2xMOo8HgcRBTJzlkf3dBhLX_nPoVjs3GE',
          title: '超方便！『 INSTO Tap 』信用卡感應式收款服務 讓你快速完成行動支付，店家免用刷卡機也能輕鬆收款！',
          date: '2021/08/10'
        },
        //  {
        //   name: '創業小聚',
        //   url: 'https://meet.bnext.com.tw/blog/view/37984',
        //   title: 'INSTO 結盟台北富邦銀行 推出人人均可使用之 INSTO Tap 信用卡感應式收款服務',
        //   date: '2021/07/31'
        // },
        {
          name: '臺北市政府產業發展局 Startup Taipei',
          url: 'https://www.startup.taipei/index.php?action=media_detail&id=339',
          title: 'INSTO Tap - 信用卡收款的最佳選擇',
          date: '2021/07/22'
        },
        {
          name: '創業小聚',
          url: 'https://meet.bnext.com.tw/articles/view/47168',
          title: '收信用卡不須刷卡機！INSTO Tap信用卡感應收款App正式上線',
          date: '2020/12/25'
        },
        {
          name: '中時新聞網',
          url: 'https://www.chinatimes.com/newspapers/20200930000473-260208?chdtv',
          title: 'INSTO盈士多科技 打造國際收付款平台',
          date: '2020/09/30'
        },
        {
          name: '工商時報',
          url: 'https://ctee.com.tw/industrynews/financesmanage/330814.html',
          title: 'INSTO盈士多科技 打造人人易用的金融科技全球通用平台',
          date: '2020/09/07'
        },
        {
          name: '工商時報',
          url: 'https://ctee.com.tw/industrynews/302875.html',
          title: 'INSTO盈士多科技建構方便信用卡線上交易平台　讓手機成為收付款設備',
          date: '2020/07/17'
        },
        // {
        //   name: '經濟日報',
        //   url: 'https://vision.udn.com/vision/story/121062/4485292',
        //   title: '北市產發局看優勢／盈士多解決市場痛點 吸引銀行目光',
        //   date: '2020/04/11'
        // },
        {
          name: '數位時代',
          url: 'https://www.bnext.com.tw/article/57092/credit-card-blue-ocean-market',
          title: '新興的金融藍海：無卡分期浪潮來襲，攻佔信用卡無法滿足的市場！',
          date: '2020/03/27'
        },
        {
          name: '中時新聞網',
          url: 'https://www.chinatimes.com/realtimenews/20200110004564-260410?chdtv ',
          title: '2019台灣P2P回顧 日生金、信用市集表現亮眼',
          date: '2020/01/10'
        },
        {
          name: '自由時報',
          url: 'https://ec.ltn.com.tw/article/breakingnews/3029275',
          title: '建立選單即可收款，INSTO讓線上刷卡付款更簡單',
          date: '2020/01/03'
        },
        {
          name: '今周刊',
          url: 'https://www.businesstoday.com.tw/article/category/80393/post/201903130034/',
          title: '矽谷創投大咖也力挺 分期付款靠一款App搞定',
          date: '2019/03/13'
        },
        {
          name: '中時新聞網',
          url: 'https://www.chinatimes.com/newspapers/20190212000301-260210?chdtv ',
          title: '挺新創 國發天使再進化',
          date: '2019/02/12'
        },
        {
          name: '自由時報',
          url: 'https://ec.ltn.com.tw/article/breakingnews/2511629',
          title: '歷經3年 INSTO可讓用戶無卡可自定分期付款 獲台灣專利',
          date: '2018/08/07'
        },
        {
          name: '自由時報',
          url: 'https://www.bnext.com.tw/article/49199/angel-of-national-development-fund',
          title: '孕育台灣第一隻獨角獸！國發天使投資啟動，10億資金挺新創',
          date: '2018/05/22'
        },
        {
          name: '史塔夫科技事務所',
          url: 'https://www.stufftaiwan.com/2018/01/19/錢帶不夠怎麼辦？-insto收付款平台在小商家用app就能分/',
          title: '錢帶不夠怎麼辦？ INSTO收付款平台在小商家用App就能分期、刷卡支付',
          date: '2018/01/19'
        },
        {
          name: 'Yahoo新聞',
          url: 'https://tw.news.yahoo.com/個股-精誠-6214-投資insto收付平台-再推insto-020340605.html',
          title: '個股：精誠(6214)投資INSTO收付平台，再推INSTO Store三大新服務',
          date: '2018/01/18'
        },
        {
          name: 'Yahoo新聞',
          url: 'https://tw.news.yahoo.com/金融-insto收付平台-h2將推insto-store服務-080305823.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAA-zTqvXgs8Gw79naTKqNn3DYkbGDPkinDStlk4zZlDd9tYGLMHJo3wT891hU3DSZUA8KDQQ8OSWOeC-EfukPckMPTagUDxKQ-30sMF3Jx1i1_ic_EGMcRUw5-Tjm6YKX1QpLnDPY4-uFuGad7sbNdA-U3vydkjIZG31e5T9XnGx',
          title: '《金融》INSTO收付平台，H2將推INSTO Store服務',
          date: '2018/01/17'
        },
        {
          name: 'INSIDE',
          url: 'https://www.inside.com.tw/article/11719-insto-app',
          title: '一個 App 搞定分期付款、訂閱服務，INSTO 獲交大天使俱樂部 120 萬美元投資',
          date: '2018/01/17'
        },
        {
          name: '電獺少女',
          url: 'https://agirls.aotter.net/post/52700',
          title: '可以分期付款付費的『Insto』點對點交易平台到底是在幹嘛?',
          date: '2018/01/17'
        },
        {
          name: '電腦王阿達',
          url: 'https://www.kocpc.com.tw/archives/180010',
          title: 'INSTO 個人賣家也能提供信用卡支付 INSTO Store 線上服務即將登場',
          date: '2018/01/17'
        },
        {
          name: '數位時代',
          url: 'https://www.bnext.com.tw/article/46232/insto-taiwan',
          title: '從C2C支付市場突圍，半年破百萬美元交易額的INSTO也要在台灣上線了',
          date: '2017/09/19'
        },
        {
          name: '數位時代',
          url: 'https://www.bnext.com.tw/article/42369/insto-installments-fintech',
          title: '分期付款新選擇！台灣金融科技 INSTO推新商業模式',
          date: '2016/12/16'
        },
        {
          name: '科技報橘',
          url: 'https://buzzorange.com/techorange/2016/12/15/taiwan-fintech-insto/',
          title: '台灣終於有個 Fintech 全球品牌！ FinTech 新創 INSTO 立足台灣拓展全球市場',
          date: '2016/12/15'
        },
        {
          name: '鉅亨網',
          url: 'https://news.cnyes.com/news/id/3568476',
          title: '分期付款革命！Installments創C2C分期付款平台',
          date: '2016/10/10'
        },
      ],
      listUSA :[
        {
          name: 'THE BUSINESS JOURNALS',
          url: 'https://www.bizjournals.com/kansascity/news/2024/10/22/insto-tap-bruce-chen-credit-card-payment-us-bank.html?utm_source=st&utm_medium=en&utm_campaign=me&utm_content=KA&ana=e_KA_me&j=37182452&senddate=2024-10-23&empos=p7',
          title: 'Entrepreneur picks KC for launch of fintech that opens credit card commerce',
          date: 'Oct 22, 2024'
        },
        {
          name: 'Meet Global',
          url: 'https://meet-global.bnext.com.tw/articles/view/48059?utm_source=copyshare&fbclid=IwZXh0bgNhZW0CMTAAAR1ilo75uiOet8myDe6FWpJQstkJdV3iDmFWj93nTA8Y-95XBuOZb_CYyEQ_aem_17o49DFWkru5hMBW9VqhVA',
          title: 'FinTech Company INSTO Enters U.S. Market, Partners with America\'s Fifth Biggest Bank',
          date: 'Jun 27, 2024'
        },
        {
          name: 'FinTech Taipei 2019',
          url: 'https://www.fsc.gov.tw/fckdowndoc?file=/FinTech%20Taipei%202019%20Program%20Book-EN.pdf&flag=doc',
          title: 'FinTech Taipei 2019',
          date: 'Nov 29-30, 2019'
        },
        // {
        //   name: 'Built In',
        //   url: 'https://builtin.com/fintech/fintech-payments-companies-examples',
        //   title: 'Fintech Payments: 25 Companies You Should Know',
        //   date: 'March 16, 2019'
        // },
        // {
        //   name: 'Paris&Co',
        //   url: 'https://www.parisandco.com/News/Latest-News/Paris-Landing-Pack_Explore-season-6-and-the-winners-are',
        //   title: 'Paris Landing Pack_Explore season 6: and the winners are',
        //   date: 'May 22, 2018'
        // },
        {
          name: 'Meet Global',
          url: 'https://meet-global.bnext.com.tw/articles/view/42312',
          title: 'Taiwanese Fintech Startup INSTO Raises $1.2 million in VC, Preps to Open Online Store',
          date: 'Feb 03, 2018'
        },
         {
          name: 'DigiTimes',
          url: 'http://digitimes.com/news/a20180118PD203.html',
          title: 'Insto Store to launch in 1H18',
          date: 'Jan 18, 2018'
        },
        {
          name: 'FinTech Futures',
          url: 'https://www.fintechfutures.com/2017/05/the-view-from-finovate/',
          title: 'The View from Finovate',
          date: 'May 18, 2017'
        },
        {
          name: 'Taiwan Today',
          url: 'https://taiwantoday.tw/news.php?unit=10&post=115200',
          title: 'MOST preps trainees for Silicon Valley talent cultivation project',
          date: 'May 11, 2017'
        },
        {
          name: 'The News Lens',
          url: 'https://international.thenewslens.com/article/66021',
          title: 'The Need for Speed: Taiwan Taking the Slow Road on Fintech',
          date: 'Apr 17, 2017'
        },
        {
          name: 'Taiwan Business TOPICS',
          url: 'https://topics.amcham.com.tw/2017/04/taiwan-opts-caution-developing-fintech/',
          title: 'Taiwan Opts for Caution in Developing Fintech',
          date: 'Apr 12, 2017'
        },
         {
          name: 'Meet Global',
          url: 'https://meet-global.bnext.com.tw/articles/view/40934',
          title: 'A pioneer in the Taiwanese financial technology industry, INSTO targets at the US market with “C2C Installment Plans”',
          date: 'Jun 18, 2017'
        },
        {
          name: 'LSN',
          url: 'https://www.lsnglobal.com/big-ideas/article/20651/scheduled-spending',
          title: 'Big Ideas : Scheduled spending',
          date: 'Jun 01, 2017'
        },
        {
          name: 'TNW',
          url: 'https://thenextweb.com/news/insto-lets-you-buy-pretty-much-anything-in-installments',
          title: 'INSTO lets you buy pretty much anything in installments',
          date: 'Dec 16, 2016'
        },
        {
          name: 'Bank Automation News',
          url: 'https://bankautomationnews.com/allposts/payments/insto-targets-installment-loans-for-large-p2p-payments/',
          title: 'Insto Targets Installment Loans for Large P2P Payments',
          date: 'Dec 15, 2016'
        },
        {
          name: 'WEBWIRE',
          url: 'https://www.webwire.com/ViewPressRel.asp?aId=206864',
          title: 'Former Ebay Exec Unveils INSTO, the First Payment App that Makes Buying Anything More Affordable Through Scheduled Installment',
          date: 'Dec 15, 2016'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#press {
  .press-wrapper {
    padding: 50px 100px;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media screen and (max-width: 900px) {
      padding: 50px;
    }
    @media screen and (max-width: 720px) {
      padding: 50px 30px;
    }
  }
}

/deep/ {
  #base-title {
    .top-banner {
    .banner-photo {
      background-image: url('../assets/imgs/page-banner/press.jpg');
      }
    }
  }
}
</style>
